import { DateTime } from 'luxon';
import { derived } from 'overmind';
import { Customer } from '../../interfaces/admin-api';
import {
    API_DATE_FORMAT,
    Contact,
    Equipment,
    EquipmentAvailabilityKPI,
    MaintenancePlan,
    MaintenanceStatus,
    PreventiveMaintenanceCompletionKPI,
    PreventiveMaintenanceCompletionKPINumbers,
    ResponseTimeKPI,
    SalesforceCase,
    SalesOrder,
    Site,
} from '../../interfaces/customer-api';
import {
    AccessRights,
    Breadcrumb,
    CustomerUser,
    DateRange,
    InsightLicenseCounts,
    InternalUser,
    Language,
    LoadingState,
    LoginState,
    TimeKeyFormat,
    UserAccess,
} from '../../interfaces/internal';
import { CaseUpdatePayload } from '../../interfaces/realtime-api';
import contactsByCountryCode from '../assets/contacts.json';
import { getMaintenancePlansForDateRange, isLoading } from '../utils';

const LICENSE_EXPIRING_SOON_MONTHS = 2;

interface PMCompletionKPIObject {
    /** Key is startDate in month format. For example '2020-01': { ... } */
    [key: string]: PreventiveMaintenanceCompletionKPI;
}

interface ResponseTimeKPIObject {
    /** Key is startDate in month format. For example '2020-01': { ... } */
    [key: string]: ResponseTimeKPI;
}

interface EquipmentAvailabilityKPIObject {
    /** Key is startDate in month, quarter or year format. For example '2020-Q1': { ... } */
    [key: string]: EquipmentAvailabilityKPI;
}

export interface KPILoadingState {
    /** Key is startDate in month format. For example '2020-01': { ... } */
    [key: string]: LoadingState;
}

export interface EquipmentObject {
    [key: string]: Equipment;
}

/**
 * "Toast" style message briefly shown on the screen
 * when e.g. certain errors occur
 */
export interface ToastMessage {
    id: number;
    message: string;
    timeout?: number;
}
export type MaintenanceCalendarState = {
    calendarDate: DateTime;
    selectedDay: DateTime;
    maintenancePlansForSelectedMonth: MaintenancePlan[];
    maintenancePlansForSelectedDay: MaintenancePlan[];
};

export type State = {
    language: Language;
    loadingStates: {
        cases: LoadingState;
        equipment: LoadingState;
        maintenancePlans: LoadingState;
        salesOrders: LoadingState;
        sites: LoadingState;
        insightUserCount: LoadingState;
        deliveries: LoadingState;
        orders: LoadingState;
        shippingDetails: LoadingState;
        equipmentAvailabilityKPIs: KPILoadingState;
        PMCompletionKPIs: KPILoadingState;
        responseTimeKPIs: KPILoadingState;
    };
    userAccess: UserAccess;
    cases: SalesforceCase[];
    casesById: Record<string, SalesforceCase>;
    caseUpdates: CaseUpdatePayload[];
    contact: Contact | null;
    equipment: Equipment[];
    siteEquipment: Equipment[];
    siteEquipmentById: EquipmentObject;
    equipmentAvailabilityKPIs: EquipmentAvailabilityKPIObject;
    siteEquipmentAvailabilityKPIs: EquipmentAvailabilityKPIObject;
    siteEquipmentAvailabilityKPIsForYear: EquipmentAvailabilityKPI[];
    isCustomerSelectorModalOpen: boolean;
    isLoadingEquipmentAvailabilityKPIs: boolean;
    isLoadingPMCompletionKPIs: boolean;
    isLoadingResponseTimeKPIs: boolean;
    isSessionExpired: boolean;
    KPIKey: string;
    loginState: LoginState;
    maintenancePlans: MaintenancePlan[];
    siteMaintenancePlans: MaintenancePlan[];
    nonDeclinedSiteMaintenancePlans: MaintenancePlan[];
    maintenanceReportingSelectedDate: DateTime;
    PMCompletionKPIs: PMCompletionKPIObject;
    sitePMCompletionKPIs: PMCompletionKPIObject;
    sitePMCompletionKPIsForYear: PreventiveMaintenanceCompletionKPI[];
    responseTimeKPIs: ResponseTimeKPIObject;
    siteResponseTimeKPIs: ResponseTimeKPIObject;
    siteResponseTimeKPIsForYear: ResponseTimeKPI[];
    salesOrders: SalesOrder[];
    selectedCustomer: Customer | null;
    selectedCustomerNumber: string | null;
    selectedEquipmentId: string | null;
    selectedEquipment: Equipment | null;
    selectedSite: string | null;
    selectedEquipmentAvailabilityKPI: EquipmentAvailabilityKPI;
    selectedPMCompletionKPI: PreventiveMaintenanceCompletionKPI;
    selectedResponseTimeKPI: ResponseTimeKPI;
    sites: Site[];
    sitesByShipTo: Record<string, Site>;
    user: CustomerUser | InternalUser | null;
    toastMessages: ToastMessage[];
    maintenanceCalendarState: MaintenanceCalendarState;
    canNavigateToMyParts: boolean;
    insightUserCount: number | null;
    insightLicenseCounts: InsightLicenseCounts;
    breadcrumbs: Breadcrumb[];
};

const getUserAccessRights = (user: CustomerUser | InternalUser | null, key: keyof UserAccess): AccessRights => {
    if (!user) return { read: false, create: false };

    return user.access[key] || { read: false, create: false };
};

export const state: State = {
    language: Language.English,
    loadingStates: {
        cases: LoadingState.NotLoaded,
        equipment: LoadingState.NotLoaded,
        maintenancePlans: LoadingState.NotLoaded,
        salesOrders: LoadingState.NotLoaded,
        sites: LoadingState.NotLoaded,
        insightUserCount: LoadingState.NotLoaded,
        deliveries: LoadingState.NotLoaded,
        orders: LoadingState.NotLoaded,
        shippingDetails: LoadingState.NotLoaded,
        equipmentAvailabilityKPIs: {},
        PMCompletionKPIs: {},
        responseTimeKPIs: {},
    },
    cases: [],
    caseUpdates: [],
    equipment: [],
    equipmentAvailabilityKPIs: {},
    isCustomerSelectorModalOpen: false,
    isSessionExpired: false,
    loginState: LoginState.NotStarted,
    maintenancePlans: [],
    maintenanceReportingSelectedDate: DateTime.local().startOf('month').minus({ months: 1 }),
    PMCompletionKPIs: {},
    responseTimeKPIs: {},
    salesOrders: [],
    selectedEquipmentId: null,
    selectedCustomer: null,
    selectedCustomerNumber: null,
    selectedSite: null,
    sites: [],
    user: null,
    toastMessages: [],
    insightUserCount: null,
    breadcrumbs: [],
    casesById: derived((state: State) => {
        const { cases } = state;
        return cases.reduce((acc, salesforceCase: SalesforceCase) => {
            acc[salesforceCase.Id] = salesforceCase;
            return acc;
        }, {} as Record<string, SalesforceCase>);
    }),
    isLoadingEquipmentAvailabilityKPIs: derived((state: State) => {
        const { loadingStates, maintenanceReportingSelectedDate } = state;
        const selectedMonthKey = maintenanceReportingSelectedDate.toFormat(TimeKeyFormat.month);
        const selectedMonthLoadingState = loadingStates.equipmentAvailabilityKPIs[selectedMonthKey];

        return isLoading(selectedMonthLoadingState);
    }),
    isLoadingPMCompletionKPIs: derived((state: State) => {
        const { loadingStates, maintenanceReportingSelectedDate } = state;
        const selectedMonthKey = maintenanceReportingSelectedDate.toFormat(TimeKeyFormat.month);

        return isLoading(loadingStates.PMCompletionKPIs[selectedMonthKey]);
    }),
    isLoadingResponseTimeKPIs: derived((state: State) => {
        const { loadingStates, maintenanceReportingSelectedDate } = state;
        const selectedMonthKey = maintenanceReportingSelectedDate.toFormat(TimeKeyFormat.month);

        return isLoading(loadingStates.responseTimeKPIs[selectedMonthKey]);
    }),
    maintenanceCalendarState: {
        calendarDate: DateTime.local().startOf('day'),
        selectedDay: DateTime.local().startOf('day'),
        maintenancePlansForSelectedMonth: derived(
            (maintenanceCalendarState: MaintenanceCalendarState, state: State) => {
                const { calendarDate } = maintenanceCalendarState;
                const { nonDeclinedSiteMaintenancePlans } = state;
                const dateRange: DateRange = {
                    startDate: calendarDate.startOf('month').toFormat(API_DATE_FORMAT),
                    endDate: calendarDate.endOf('month').toFormat(API_DATE_FORMAT),
                };
                return getMaintenancePlansForDateRange(nonDeclinedSiteMaintenancePlans, dateRange);
            },
        ),
        maintenancePlansForSelectedDay: derived((maintenanceCalendarState: MaintenanceCalendarState, state: State) => {
            const { selectedDay } = maintenanceCalendarState;
            const { nonDeclinedSiteMaintenancePlans } = state;
            const dateRange: DateRange = {
                startDate: selectedDay.toFormat(API_DATE_FORMAT),
                endDate: selectedDay.toFormat(API_DATE_FORMAT),
            };
            return getMaintenancePlansForDateRange(nonDeclinedSiteMaintenancePlans, dateRange);
        }),
    },
    KPIKey: derived((state: State) =>
        state.maintenanceReportingSelectedDate.startOf('month').toFormat(TimeKeyFormat.month),
    ),
    selectedEquipment: derived((state: State) => {
        const { siteEquipment, selectedEquipmentId } = state;
        if (selectedEquipmentId === null) return null;
        return siteEquipment.find((equ) => equ.equipment_number === selectedEquipmentId) || null;
    }),
    siteEquipment: derived((state: State) => {
        const { selectedSite, equipment } = state;
        if (!selectedSite) return equipment;

        return equipment.filter(({ business_partner_sh }) => business_partner_sh === selectedSite);
    }),
    siteEquipmentById: derived((state: State) => {
        const { siteEquipment } = state;
        return siteEquipment.reduce((acc, equ) => {
            acc[equ.technical_identificationnumber || equ.serial_number] = equ;
            return acc;
        }, {} as EquipmentObject);
    }),
    siteEquipmentAvailabilityKPIs: derived((state: State) => {
        const { selectedSite, equipmentAvailabilityKPIs } = state;
        if (!selectedSite) return state.equipmentAvailabilityKPIs;

        return Object.entries(equipmentAvailabilityKPIs).reduce((previous, [date, { bySite, startTime, endTime }]) => {
            const site = bySite && bySite[selectedSite];
            return {
                ...previous,
                [date]: {
                    average: site ? site.average : null,
                    byType: site ? site.byType : null,
                    bySite,
                    startTime,
                    endTime,
                },
            };
        }, {} as EquipmentAvailabilityKPIObject);
    }),
    selectedEquipmentAvailabilityKPI: derived((state: State) => {
        return state.siteEquipmentAvailabilityKPIs[state.KPIKey];
    }),
    siteMaintenancePlans: derived((state: State) => {
        const { selectedSite, maintenancePlans } = state;
        if (!selectedSite) return maintenancePlans;

        return maintenancePlans.filter((plan) => plan.siteNumber === selectedSite);
    }),
    nonDeclinedSiteMaintenancePlans: derived((state: State) => {
        const { siteMaintenancePlans } = state;

        return siteMaintenancePlans.filter((plan) => plan.status !== MaintenanceStatus.DECLINED);
    }),
    sitePMCompletionKPIs: derived((state: State) => {
        const { selectedSite, PMCompletionKPIs } = state;
        if (!selectedSite) return PMCompletionKPIs;

        const nullPMCompletion: PreventiveMaintenanceCompletionKPINumbers = {
            completed: 0,
            percentage: 0,
            planned: 0,
        };

        return Object.entries(PMCompletionKPIs).reduce((previous, [date, { bySite, startTime, endTime }]) => {
            const site = bySite && bySite[selectedSite];
            return {
                ...previous,
                [date]: {
                    all: site ? site.all : nullPMCompletion,
                    byType: site ? site.byType : {},
                    bySite,
                    startTime,
                    endTime,
                },
            };
        }, {} as PMCompletionKPIObject);
    }),
    selectedPMCompletionKPI: derived((state: State) => {
        return state.sitePMCompletionKPIs[state.KPIKey];
    }),
    siteResponseTimeKPIs: derived((state: State) => {
        const { selectedSite, responseTimeKPIs } = state;
        if (!selectedSite) return responseTimeKPIs;
        return Object.entries(responseTimeKPIs).reduce((previous, [date, { bySite, startTime, endTime }]) => {
            const site = bySite && bySite[selectedSite];
            return {
                ...previous,
                [date]: {
                    average_minutes: site ? site.average_minutes : null,
                    callouts: site ? site.callouts : null,
                    bySite,
                    startTime,
                    endTime,
                },
            };
        }, {} as ResponseTimeKPIObject);
    }),
    selectedResponseTimeKPI: derived((state: State) => {
        return state.siteResponseTimeKPIs[state.KPIKey];
    }),
    userAccess: derived((state: State) => {
        const { user } = state;

        return {
            equipment: getUserAccessRights(user, 'equipment'),
            cases: getUserAccessRights(user, 'cases'),
            maintenancePlans: getUserAccessRights(user, 'maintenancePlans'),
            maintenanceReporting: getUserAccessRights(user, 'maintenanceReporting'),
            salesOrders: getUserAccessRights(user, 'salesOrders'),
            contacts: getUserAccessRights(user, 'contacts'),
            automationSupport: getUserAccessRights(user, 'automationSupport'),
            kalmarInsight: getUserAccessRights(user, 'kalmarInsight'),
            delivery: getUserAccessRights(user, 'delivery'),
            order: getUserAccessRights(user, 'order'),
            shippingDetails: getUserAccessRights(user, 'shippingDetails'),
            dealerCommunity: getUserAccessRights(user, 'dealerCommunity'),
            mascusWidget: getUserAccessRights(user, 'mascusWidget'),
            documentationPage: getUserAccessRights(user, 'documentationPage'),
        };
    }),
    canNavigateToMyParts: derived((state: State) => {
        const { user } = state;
        if (!user) return false;

        // Internal users do not have SAP customer number
        if (user.isAdmin) return false;

        // User must have myParts active and have a SAP customer number
        if (!user.myParts?.active || !user.sapCustomerNumber) return false;

        // Users equipment filter must include the SAP customer number
        const userCustomerNumbers = Object.keys(user.equipmentFilter);
        const isAllowed = userCustomerNumbers.includes(user.sapCustomerNumber);
        if (!isAllowed) return false;

        return true;
    }),
    siteEquipmentAvailabilityKPIsForYear: derived((state: State) => {
        const { siteEquipmentAvailabilityKPIs, maintenanceReportingSelectedDate } = state;
        const startMonthKey = maintenanceReportingSelectedDate.startOf('year').toFormat(TimeKeyFormat.month);
        const endMonthKey = maintenanceReportingSelectedDate.endOf('year').toFormat(TimeKeyFormat.month);

        return Object.keys(siteEquipmentAvailabilityKPIs)
            .sort()
            .filter((monthKey) => monthKey >= startMonthKey && monthKey <= endMonthKey)
            .map((monthKey) => siteEquipmentAvailabilityKPIs[monthKey]);
    }),
    sitePMCompletionKPIsForYear: derived((state: State) => {
        const { sitePMCompletionKPIs, maintenanceReportingSelectedDate } = state;
        const startMonthKey = maintenanceReportingSelectedDate.startOf('year').toFormat(TimeKeyFormat.month);
        const endMonthKey = maintenanceReportingSelectedDate.endOf('year').toFormat(TimeKeyFormat.month);

        return Object.keys(sitePMCompletionKPIs)
            .sort()
            .filter((monthKey) => monthKey >= startMonthKey && monthKey <= endMonthKey)
            .map((monthKey) => sitePMCompletionKPIs[monthKey]);
    }),
    siteResponseTimeKPIsForYear: derived((state: State) => {
        const { siteResponseTimeKPIs, maintenanceReportingSelectedDate } = state;

        const startMonthKey = maintenanceReportingSelectedDate.startOf('year').toFormat(TimeKeyFormat.month);
        const endMonthKey = maintenanceReportingSelectedDate.endOf('year').toFormat(TimeKeyFormat.month);

        return Object.keys(siteResponseTimeKPIs)
            .sort()
            .filter((monthKey) => monthKey >= startMonthKey && monthKey <= endMonthKey)
            .map((monthKey) => siteResponseTimeKPIs[monthKey]);
    }),
    sitesByShipTo: derived((state: State) => {
        const { sites } = state;
        return sites.reduce((acc, site: Site) => {
            acc[site.ship_to_number] = site;
            return acc;
        }, {} as Record<string, Site>);
    }),
    insightLicenseCounts: derived((state: State) => {
        const counts = {
            valid: 0,
            expired: 0,
            expiring: 0,
        };

        const now = DateTime.local();
        const soon = DateTime.local().plus({ months: LICENSE_EXPIRING_SOON_MONTHS });

        for (const eq of state.siteEquipment) {
            for (const contract of eq.contracts) {
                const contract_end = DateTime.fromFormat(contract.contract.contract_end, 'yyyy-MM-dd');
                if (contract.type !== 'insight') continue;

                if (contract_end < now) {
                    counts.expired++;
                } else {
                    counts.valid++;
                    if (contract_end < soon) {
                        counts.expiring++;
                    }
                }
            }
        }

        return counts;
    }),
    contact: derived((state: State) => {
        const { sitesByShipTo, selectedSite, sites } = state;

        let countryCode = null;
        if (selectedSite) {
            countryCode = sitesByShipTo[selectedSite].country_code;
        } else if (sites.length > 0) {
            countryCode = sites[0].country_code;
        }

        if (!countryCode) {
            return null;
        }

        const contact = (contactsByCountryCode as Record<string, Contact>)[countryCode];
        return contact ?? null;
    }),
};
